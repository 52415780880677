
import CustomTooltip, { TOOLTIP_DIRECTION } from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: { CustomTooltip },
})
export default class InsightsHintTooltip extends Vue {
    hint: HTMLElement | null = null;
    offset = { y: 70 };
    RIGHT = TOOLTIP_DIRECTION.RIGHT;

    toggleHover(e: MouseEvent) {
        this.hint = this.hint ? null : e.target as HTMLElement;
    }
}
