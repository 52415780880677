
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { RouterLink } from 'vue-router';
import moment from 'moment';
import Dialog from '@/modules/common/components/ui-kit/dialog.vue';
import InsightsService, { InsightsServiceS } from '../insights.service';
import { InsightType } from '../constants';
import InsightTypeLabel from './insight-type-label.vue';

@Component({
    components: {
        RouterLink,
        Dialog,
        InsightTypeLabel,
    },
})
export default class InsightCard extends Vue {
    @inject(InsightsServiceS) private insightsService!: InsightsService;

    @Prop()
    id!: string;

    @Prop()
    insightType!: {
        subType: number;
        value: InsightType;
        name: string;
        color: string;
        bgcolor: string;
    };

    @Prop()
    date!: Date;

    @Prop()
    contentLink!: string;

    @Prop({ default: false })
    isNew!: boolean;

    @Prop({ default: false })
    isDeleted!: boolean;

    isPending = false;
    isDeleteConfirmation = false;

    get formatedDate() {
        return this.date ? this.$t('insights.fromDate', [moment(this.date).format('MMMM DD, YYYY')]) : '--/--/--';
    }

    async handleToggleViewed() {
        this.isPending = true;
        if (this.isNew) {
            await this.insightsService.setGroupViewed(this.id);
        } else {
            await this.insightsService.setGroupUnviewed(this.id);
        }
        this.isPending = false;
    }

    async handleDelete() {
        this.isPending = true;
        await this.insightsService.deleteGroup(this.id);
        this.isPending = false;
        this.isDeleteConfirmation = false;
    }

    text(type: InsightType, subType: number) {
        switch (type) {
            case InsightType.RATE:
                if (subType === 0) {
                    return this.$t('insights.newRate');
                }
                return this.$t('insights.missingRate');
            case InsightType.LOS:
                if (subType === 0) {
                    return this.$t('insights.losLower');
                }
                return this.$t('insights.losHigher');
            case InsightType.PROMOTION:
                if (subType === 0) {
                    return this.$t('insights.newPromotion');
                }
                return this.$t('insights.newDiscount');
            case InsightType.VISIBILITY:
                if (subType === 0) {
                    return this.$t('insights.lowerPosition');
                }
                return this.$t('insights.higherPosition');
            case InsightType.ROOMS:
                return this.$t('insights.newRoom');
            case InsightType.DEVICE:
                return this.$t('insights.deviceDiff');
            case InsightType.RATE_TRENDS:
                if (subType === 1) {
                    return this.$t('insights.trendIncrease');
                }
                return this.$t('insights.trendDecrease');
            case InsightType.MARKET_LEADER:
                return this.$t('insights.leaderDetection');
            default:
                return '';
        }
    }
}
