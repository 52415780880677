
import { Prop, Component, Vue } from 'vue-property-decorator';

@Component({})
export default class DotsSpinner extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    })
    value!: boolean;
}
